export const imageSizes = {
  square: {
    medium: {
      width: 240,
      height: 240,
    },
    large: {
      width: 480,
      height: 480,
    },
  },
  landscape: {
    medium: {
      width: 600,
      height: 315,
    },
    large: {
      width: 1200,
      height: 630,
    },
  },
  portrait: {
    medium: {
      width: 540,
      height: 960,
    },
    large: {
      width: 1080,
      height: 1920,
    },
  },
} as const;

export const MAX_SIZE = 1920;

export type ImageType = keyof typeof imageSizes;
export type ImageSize = keyof (typeof imageSizes)[ImageType];

export const HERO_WIDTH_LARGE = 1200;
export const HERO_HEIGHT_LARGE = 800;
export const HERO_WIDTH_MEDIUM = 800;
export const HERO_HEIGHT_MEDIUM = 600;
export const HERO_WIDTH_SMALL = 600;
export const HERO_HEIGHT_SMALL = 400;

export const GALLERY_WIDTH_THUMBNAIL = 420;
export const GALLERY_HEIGHT_THUMBNAIL = 420;

export const GALLERY_WIDTH_SMALL = 800;
export const GALLERY_WIDTH_MEDIUM = 1600;
export const GALLERY_WIDTH_LARGE = 2400;

export const AVATAR_WIDTH_SMALL = 120;
export const AVATAR_HEIGHT_SMALL = 120;
export const AVATAR_WIDTH_LARGE = 440;
export const AVATAR_HEIGHT_LARGE = 440;

export const IMAGE_TYPES = {
  HERO: "hero",
  LOGO: "logo",
  PHOTO: "photo",
} as const;
